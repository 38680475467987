import React from "react"
import styled from "styled-components"

type StrikedTextProps = {
  text: string
  textColor: string
  strikeColor: string,
  type: string,
  fontWeight?: any
}

const StrikedText = ({ text, textColor, strikeColor, type, fontWeight=600}: StrikedTextProps) => {
  return (
    <StrikedTextContainer textColor={textColor} strikeColor={strikeColor}>
      <span className="strike-wrapper"> 
        <span className="text-and-underline-container"> 
          { type !== "" ?
            <a href={type+":"+text} style={{fontWeight: fontWeight}}>{text}</a>
            : <span>{text}</span>
          }
          { type !== "" ?
            <div className="underline-hoverable"></div>
            : <div className="underline"></div>
          }
        </span>
      </span>
    </StrikedTextContainer>
  )
}

export default StrikedText
const StrikedTextContainer = styled.span<{ textColor: string, strikeColor: string}>`
  .strike-wrapper {
    color: ${props => (props.textColor)};

    .text-and-underline-container {
      position: relative;
      padding: "0 5px";

      a, span {
        z-index: 1000;
        position: relative;
        color: ${props => (props.textColor)};
      }
    }
    .underline, .underline-hoverable {
      background-color: ${props => (props.strikeColor)};
      position: absolute;
      bottom: 14%;
      left: 0;
      right: 0;
      height: 12%;
      border-radius: 4px;
      z-index: 999
    }

    a:focus {
      color: ${props => (props.strikeColor)};
    }

    :hover {
      a {
        color: ${props => (props.strikeColor)};
      }
      .underline-hoverable {
        background-color: ${props => (props.textColor)};
      }
    }
  }
`;